import { DomainIcon } from './DomainIcon';

/**
 * author: Google
 * id CA264BA4-62A9-4823-A172-22DD413A6CF5
 * name domain
 * version: 1.5.54
 * url https://raw.githubusercontent.com/Templarian/MaterialDesign/master/svg/domain.svg
 * tags
 * - Places 
 */
export const CompanyIcon = DomainIcon;
export default CompanyIcon