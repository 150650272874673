import React from "react";
import createSvgIcon from "@material-ui/core/utils/createSvgIcon";

/**
 * author: Google
 * id 2DE4F8E6-F25C-4FCA-8405-109FA8918B78
 * name plus
 * version: 1.5.54
 * url https://raw.githubusercontent.com/Templarian/MaterialDesign/master/svg/plus.svg
 * tags
 * - Math 
 */
export const PlusIcon = createSvgIcon(<><path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" /></>, 'PlusIcon');
export default PlusIcon