import React from "react";
import createSvgIcon from "@material-ui/core/utils/createSvgIcon";

/**
 * author: Google
 * id 84FE90C1-076D-4EA1-B56F-4C2582A39AC3
 * name chevron-down
 * version: 1.5.54
 * url https://raw.githubusercontent.com/Templarian/MaterialDesign/master/svg/chevron-down.svg
 * tags
 * - Arrow 
 */
export const ChevronDownIcon = createSvgIcon(<><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></>, 'ChevronDownIcon');
export default ChevronDownIcon