import React from "react";
import createSvgIcon from "@material-ui/core/utils/createSvgIcon";

/**
 * author: Google
 * id 0BD2CD08-CCFB-4EC3-B96D-08B0B8230A91
 * name menu
 * version: 1.5.54
 * url https://raw.githubusercontent.com/Templarian/MaterialDesign/master/svg/menu.svg
 * tags
 */
export const MenuIcon = createSvgIcon(<><path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" /></>, 'MenuIcon');
export default MenuIcon