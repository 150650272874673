import React from "react";
import createSvgIcon from "@material-ui/core/utils/createSvgIcon";

/**
 * author: Michael Richins
 * id A41E35E2-6756-46D9-BE48-4DFA705A6A6A
 * name handshake
 * version: 4.6.95
 * url https://raw.githubusercontent.com/Templarian/MaterialDesign/master/svg/handshake.svg
 * tags
 */
export const HandshakeIcon = createSvgIcon(<><path d="M11 6H14L17.29 2.7A1 1 0 0 1 18.71 2.7L21.29 5.29A1 1 0 0 1 21.29 6.7L19 9H11V11A1 1 0 0 1 10 12A1 1 0 0 1 9 11V8A2 2 0 0 1 11 6M5 11V15L2.71 17.29A1 1 0 0 0 2.71 18.7L5.29 21.29A1 1 0 0 0 6.71 21.29L11 17H15A1 1 0 0 0 16 16V15H17A1 1 0 0 0 18 14V13H19A1 1 0 0 0 20 12V11H13V12A2 2 0 0 1 11 14H9A2 2 0 0 1 7 12V9Z" /></>, 'HandshakeIcon');
export default HandshakeIcon