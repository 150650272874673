import React from "react";
import createSvgIcon from "@material-ui/core/utils/createSvgIcon";

/**
 * author: Google
 * id 8D4C203D-A1F0-42DA-997A-AB7BAC63D97B
 * name grid
 * version: 1.5.54
 * url https://raw.githubusercontent.com/Templarian/MaterialDesign/master/svg/grid.svg
 * tags
 */
export const GridIcon = createSvgIcon(<><path d="M10,4V8H14V4H10M16,4V8H20V4H16M16,10V14H20V10H16M16,16V20H20V16H16M14,20V16H10V20H14M8,20V16H4V20H8M8,14V10H4V14H8M8,8V4H4V8H8M10,14H14V10H10V14M4,2H20A2,2 0 0,1 22,4V20A2,2 0 0,1 20,22H4C2.92,22 2,21.1 2,20V4A2,2 0 0,1 4,2Z" /></>, 'GridIcon');
export default GridIcon