import React from "react";
import createSvgIcon from "@material-ui/core/utils/createSvgIcon";

/**
 * author: Google
 * id 2CEA6B7B-E7C9-4D52-9D9D-286E50001B70
 * name minus
 * version: 1.5.54
 * url https://raw.githubusercontent.com/Templarian/MaterialDesign/master/svg/minus.svg
 * tags
 * - Math 
 */
export const MinusIcon = createSvgIcon(<><path d="M19,13H5V11H19V13Z" /></>, 'MinusIcon');
export default MinusIcon